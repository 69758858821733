import { computed, ref } from "vue"

const videoCache = () => {

  const cachedlink = ref('')
  const hascachedlink = computed(() => {
    return cachedlink.value !== ''
  })



  const validateLink = (link) => {
    if (!link.includes('youtu')) {
      link = ''
      return null
    }
    let x = link
    //Youtube shorts
    if (x.includes('shorts')) {
      if (x.includes('?')) {
        x = x.split('?')[0]
      }
      x = x.replace('shorts/', 'embed/')
    }
    //Youtube normal
    if (x.includes('watch')) {
      x = x.replace('watch?v=', 'embed/')
    }
    //Youtube mobile
    if (x.includes('youtu.be')) {
      x = x.split('?')[0]
      x = x.split('.be/')[1]
      x = 'https://www.youtube.com/embed/' + x
    }
    //Remove playlist
    if (x.includes('&list')) {
      x = x.split('&list')[0]
    }
    x += '?autoplay=1'
    link = x
    cachedlink.value = x
  }

  return { cachedlink, hascachedlink, validateLink }
}

export default videoCache