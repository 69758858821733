<template>
  <textarea spellcheck="false" cols="30" rows="10" id="txt"></textarea>
</template>
<script>
import { ref, watch, watchEffect } from 'vue';
import tasklistMaker from '@/composables/tasklistMaker';
export default {
  props: ['tasks', 'clockedout', 'savedtext'],
  setup(props) {
    const loaded = ref(false)


    const { prevlists } = tasklistMaker()
    const makeList = () => {
      if (!loaded.value) {
        return null
      }
      const textarea = document.getElementById('txt')
      textarea.value = ''
      if (props.savedtext !== undefined) {
        textarea.value += props.savedtext
      }
      textarea.value += props.tasks
    }
    const emptyList = () => {
      if (!loaded.value) {
        return null
      }
      const textarea = document.getElementById('txt')
      textarea.value = ''
    }

    const makeClockOutList = () => {
      if (!loaded.value) {
        return null
      }
      const textarea = document.getElementById('txt')
      textarea.value = ''
      textarea.value += props.savedtext
    }

    watch(props.tasks, () => {
    })

    watchEffect(() => {
      if (loaded) {
        if (props.tasks) {
          if (props.clockedout) {
            makeClockOutList()
          }
          else {
            makeList()
          }
        }
        else {
          emptyList()
        }
      }
    })
    return { makeList, loaded }
  },
  mounted() {
    this.loaded = true
  }
}
</script>

<style scoped>
p.task {
  font-style: italic;
}

textarea {
  text-align: center;
  border-radius: 5px;
  font-size: 20px;
}
</style>