import { ref, computed } from "vue"
const tasklistMaker = () => {

  const prevlists = ref([])

  const haspreviouslists = computed(() => {
    return prevlists.value.length > 0
  })

  //takes list and turns it into a string
  const getTaskList = (list) => {
    let liststring = ''
    for (let i = 0; i < list.length; i++) {
      if (i === list.length) {
        break
      }
      let x = 'TASK' + list[i].task + ' ' + list[i].starttime + ' est ' + list[i].tasklength + '\n'
      liststring += x
    }
    return liststring
  }

  //uses gettasklsit then adds the last goal date of the last task and adds clocked out msg
  const getClockOutList = (list) => {
    let l = getTaskList(list)
    let ge = list[list.length - 1].goalend
    let d = new Date,
      now = d.toLocaleTimeString()
    l += 'Last goal time ' + ge + '\n'
    l += 'Clocked out at ' + now + '\n'
    return l
  }

  const fullList = (list) => {
    let l = ''
    if (haspreviouslists.value) {
      for (let i = 0; i < prevlists.value.length; i++) {
        l += prevlists.value[i]
      }
    }
    l += getClockOutList(list)
    return l
  }

  //saves to a saved list
  const saveList = (list) => {
    prevlists.value.push(list)
  }

  return { getTaskList, prevlists, haspreviouslists, getClockOutList, saveList, fullList }
}

export default tasklistMaker