<template>
  <div>{{ goaltime }}</div>
</template>

<script>
import { ref, computed, watchEffect } from 'vue'


export default {
  props: ['taskmin'],
  setup(props) {
    const goaltime = ref('00:00:00')

    const mins = computed(() => {
      return props.taskmin
    })

    const setGoalTime = () => {
      let t = new Date(),
        x = new Date(t.getTime() + props.taskmin * 60000).toLocaleTimeString()
      if (x === 'Invalid Time') {
        goaltime.value = 'Enter time'
      }
      else {
        goaltime.value = x
      }
    }

    watchEffect(() => {
      setGoalTime(), (props.taskmin >= 1)
    })

    return { mins, goaltime, setGoalTime }
  }
}
</script>

<style scoped>
div {
  width: 370px;
  height: 50px;
  font-size: 44px;
  text-align: center;
  margin: 0 auto;
}
</style>