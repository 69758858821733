<template>
  <div class="clock">{{ ticktime }}</div>
  <div v-if="tracking" class="clock"> {{ goaltime }} </div>
</template>

<script>
import { ref, watchEffect } from 'vue';



export default {
  props: ['taskstarted', 'taskmin'],
  emits: ['clockstarted', 'taskdone'],
  setup(props, { emit }) {
    const ticktime = ref('00:00:00')
    const goaltime = ref('')
    const tracking = ref('')

    const tickClock = () => {
      var t = new Date()
      ticktime.value = t.toLocaleTimeString()
      if (ticktime.value === goaltime.value) {
        tracking.value = false
      }
    }

    watchEffect(() => {
      if (props.taskstarted) {
        var t = new Date()
        goaltime.value = new Date(t.getTime() + props.taskmin * 60000).toLocaleTimeString()
        tracking.value = true
        emit('clockstarted', '')
      }
    })
    setInterval(tickClock, 1000)
    return { ticktime, goaltime, tracking }
  }
}
</script>

<style>
.clock {
  width: 370px;
  height: 50px;
  font-size: 44px;
  text-align: center;
  margin: 0 auto;
}
</style>