<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <div class="vuestuff">
    <h1>the TASK TIMER</h1>
    <TimeTracker @done="doneHandle" @start="startHandle" @undo="undoHandle" @clockout="clockoutHandle" />
    <VideoFrame :task=done />
    <p @click="showtext = !showtext">{{ showhide }} Task list</p>
    <TaskTextbox v-if="showtext" :tasks="tasks" :clockedout="clockedout" :savedtext="savedtext" />
    <router-view />
  </div>

  <!--   <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
</template>

<script>
import TimeTracker from "./components/TimeTracker.vue";
import VideoFrame from "./components/VideoFrame.vue";
import TaskTextbox from "./components/TaskTextbox.vue"
import { ref, computed } from "vue";

export default {
  components: { TimeTracker, VideoFrame, TaskTextbox },
  setup() {
    const done = ref(true)
    const tasks = ref('')
    const showtext = ref(false)
    const clockedout = ref(false)
    const savedtext = ref('')

    const showhide = computed(() => {
      if (showtext.value) {
        return 'Hide'
      }
      return 'Show'
    })

    const startHandle = (list) => {
      if (clockedout.value) {
        clockedout.value = false
      }
      done.value = false
      tasks.value = list
    }

    const doneHandle = () => {
      document.title = 'Done'
      done.value = true
    }

    const undoHandle = (list) => {
      document.title = 'Task Timer'
      done.value = true
      tasks.value = list
    }

    const clockoutHandle = (list) => {
      savedtext.value = list
      clockedout.value = true
    }

    return { showhide, done, undoHandle, doneHandle, startHandle, clockoutHandle, tasks, showtext, clockedout, savedtext }
  },
  mounted() {
    document.title = "Task Timer"
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media (max-width: 1024px) {
  div .vuestuff {
    max-width: 100%;
    margin: 0 auto;
  }

  button {
    justify-content: space-evenly;
  }
}

div .vuestuff {
  margin: 0 auto;
  width: 500px;
  border: 1px outset #999;
}

h1 {
  color: #b5b6f5;
  text-shadow: 2px 2px rgba(2, 24, 37, 0.4);
  rotate: -4.5deg;
}

h1:hover {
  cursor: default;
}

body {
  height: 100%;
  overflow: auto;
  background-color: #eee;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
