<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <div class="vidclass" v-if="task === true">
    <input placeholder="Paste your favorite Youtube video in and hit enter" @keydown.enter.prevent="tryGetLink"
      v-model="inputtext" type="text">
    <br>
    <iframe id="vid" width="420" height="315" :src="vid" allow="autoplay"></iframe>
    <!-- <p class="reminder" @click="displaymutewarning = !displaymutewarning" v-if="displaymutewarning">Don't forget
      to unmute, as autoplay
      embeds aren't allowed without mute.
      <br>
      (Click to dismiss this message)
    </p> -->
  </div>
</template>
<script>
import videoCache from '@/composables/videoCache'
import { ref, watch, watchEffect } from 'vue'

export default {
  props: ['task'],
  setup(props) {
    const displaymutewarning = ref('true')
    const canshow = ref('')
    const inputtext = ref('')
    const vid = ref('https://www.youtube.com/embed/e3ZvuaqK66g?autoplay=1&loop=1&mute=1')
    const { validateLink, cachedlink } = videoCache()

    const tryGetLink = () => {
      validateLink(inputtext.value)
      vid.value = cachedlink.value
    }

    const saveDefault = () => {
      if (vid.value === 'https://www.youtube.com/embed/e3ZvuaqK66g?autoplay=1&loop=1&mute=1') {
        let x = 'https://www.youtube.com/watch?v=e3ZvuaqK66g'
        validateLink(x)
        vid.value = cachedlink.value
      }
    }

    watchEffect(() => {
      if (!props.task) {
        saveDefault()
      }
    })
    return { vid, inputtext, tryGetLink, canshow, displaymutewarning, saveDefault }
  },
  mounted() {
  },
  unmounted() {
    this.saveDefault()
  }

}
</script>
<style scoped>
.reminder {
  cursor: pointer;
  font-style: italic;
  font-size: 12px;
}

@media screen and (max-width: 1024px) {
  iframe {
    height: 450px;
    width: 100%;
  }

  input {
    width: 100%;
    font-size: 12px;
  }
}

input {
  text-align: center;
  height: 25px;
  width: 75%;
  border-radius: 10px;
}

iframe {
  border-radius: 10px;
  border-width: 1px;
}
</style>