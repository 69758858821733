<template>
  <div class="home">
  </div>
</template>

<script>
import { ref } from "vue";


export default {
  components: {},
  name: 'HomeView',
  setup() {
  }
}
</script>

<style>
button {
  cursor: pointer;
}
</style>
