<template>
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <p v-show="clockedin" style="cursor:hover: default;" @click="clockOut">{{ clockoutmsg }}</p>
  <div class="clock">{{ nowtime }}</div>
  <div class="taskinput">
    <div class="one">
      <p>Task length</p>
      <input c @keydown.enter.prevent="startTask" v-model="inputmin" type="number">
    </div>
    <div>
      <button @click="startTask" v-show="!ontask">{{ buttontext }}</button>
      <button @click="undoTask" v-show="ontask">Undo</button>
    </div>
  </div>
  <div class="clock">{{ goaltime }}</div>
</template>


<script>
import { HackTimer, HackTimerWorker } from 'hacktimer'
import Clock from './Clock.vue'
import GoalClock from './GoalClock.vue'
import { computed, ref, watchEffect } from 'vue'
import clockTick from '@/composables/clockTick'
import tasklistMaker from '@/composables/tasklistMaker'


export default {
  props: [],
  emits: ['done', 'start', 'undo', 'clockout'],
  setup(props, { emit }) {
    const taskmin = ref('')
    const ontask = ref('')
    const inputmin = ref('')
    const tasknum = ref(0)
    const tasklist = ref([])
    const { goaltime, nowtime, tick, makeGoalClock, checkFinish, undoTaskTimer } = clockTick()
    const { getTaskList, saveList, getClockOutList, fullList, haspreviouslists } = tasklistMaker()

    const clockoutconfirm = ref('')
    const loadingclock = computed(() => {
      return (nowtime.value === '00:00')
    })
    const canclockout = computed(() => {
      return (!ontask.value && tasklist.value.length > 0)
    })


    const clockoutmsg = computed(() => {
      if (!canclockout.value) {
        return 'Clocked in'
      }
      if (!clockoutconfirm.value) {
        return 'Clocked in (Click to clock out)'
      }
      if (clockoutconfirm.value === 1) {
        return 'Are you sure?'
      }
      if (clockoutconfirm.value === 2) {
        return 'Clocked out'
      }
    })

    setInterval(tick, 1000)

    const buttontext = computed(() => {
      if (inputmin.value < 1) {
        return 'Enter time'
      }
      return 'Start task'
    })

    const clockedin = computed(() => {
      return tasklist.value.length > 0
    })

    const startTask = () => {
      if (inputmin.value < 1 || loadingclock.value) {
        return null
      }
      tasknum.value++
      taskmin.value = inputmin.value
      ontask.value = true
      makeGoalClock(taskmin.value)
      tasklist.value.push({ 'task': tasknum.value, 'starttime': nowtime.value, 'goalend': goaltime.value, 'tasklength': taskmin.value + 'm' })
      emit('start', getTaskList(tasklist.value))
      clockoutconfirm.value = ''
    }

    const undoTask = () => {
      if (tasklist.value.length === 0) {
        return null
      }
      tasknum.value--
      tasklist.value.pop()
      undoTaskTimer()
      ontask.value = false
      emit('undo', getTaskList(tasklist.value))
    }

    const clockOut = () => {
      if (!canclockout.value) {
        return null
      }
      if (!clockoutconfirm.value) {
        clockoutconfirm.value = 1
        return null
      }
      if (clockoutconfirm.value = 1) {
        clockoutconfirm.value = 2
        let x = fullList(tasklist.value)
        saveList(x)
        tasklist.value = []
        emit('clockout', x)
        return null
      }
      if (clockoutconfirm.value = 2) {
        clockoutconfirm.value = ''
      }
    }

    watchEffect(() => {
      if (checkFinish.value) {
        ontask.value = false
        emit('done')
      }

    })

    return { buttontext, clockedin, clockoutmsg, goaltime, inputmin, ontask, taskmin, nowtime, startTask, undoTask, makeGoalClock, clockOut }
  },
  components: { Clock, GoalClock }
}
</script>


<style scoped>
@media (max-width: 1024px) {
  .one {
    transform: translateX(-25px);
  }

  button {
    transform: translateX(25px);
  }
}

.taskinput {
  display: flex;
  justify-content: space-evenly;

}

.one {
  margin: 0 -7.5em 0 0;
}

input {
  text-align: center;
  height: 27px;
  width: 50px;
  border-radius: 7px;
}

.clock {
  width: 370px;
  height: 50px;
  font-size: 44px;
  text-align: center;
  margin: 0 auto;
  cursor: default;
}

button {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 17px;
  width: 100px;
  height: 50px;
  border-radius: 7px;
  border-width: 1px;
  background: white;
  font-style: italic;
}

p {
  margin: 0 auto;
  font-style: italic;
}

p:hover {
  cursor: default;
}
</style>