import { computed, ref } from "vue"

const clockTick = () => {
  const nowtime = ref('00:00')
  const goaltime = ref('00:00:00')
  const sec = ref('')

  const titlecountdown = computed(() => {
    if (sec.value > 3600) {
      return new Date(sec.value * 1000).toISOString().substring(11, 16) + 'm'
    }
    return new Date(sec.value * 1000).toISOString().substring(14, 19)
  })

  const canupdatetitle = computed(() => {
    return sec.value > 0
  })

  const tick = () => {
    var t = new Date
    nowtime.value = t.toLocaleTimeString()
    if (canupdatetitle.value) {
      sec.value -= 1
      document.title = titlecountdown.value
    }
  }
  const makeGoalClock = (time) => {
    sec.value = time * 60
    let t = new Date,
      x = new Date(t.getTime() + time * 60000).toLocaleTimeString()
    goaltime.value = x
  }

  const undoTaskTimer = () => {
    sec.value = ''
    goaltime.value = '00:00:00'
  }

  const checkFinish = computed(() => {
    return nowtime.value === goaltime.value
  })


  return { goaltime, nowtime, tick, makeGoalClock, checkFinish, undoTaskTimer }
}

export default clockTick